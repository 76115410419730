const ImageZoom4i = function (mainImgSlider) {
	let zoomImages, productImages, increment, zoomSliderHTML, gestureBtns, mainSlider, zoomInBtn, zoomOutBtn, a11yStatus,
	moreGesturesArea, leftBtn, rightBtn, zoomSlider,
	imgSlider = mainImgSlider,

	init = () => {
		initVariables();
		
		zoomImages.forEach((el) => {
			el.addEventListener("mousemove", zoomThis);
			el.addEventListener("mouseleave", removeZoom);
		});
		
		gestureBtns.addEventListener("click", a11yEvents);
		imgSlider.events.on('transitionStart', resetZoom);

		if (window.innerWidth < 800)
			setUpZoomDialog();
		else  
			setUpWithoutDialog();
	},
	initVariables = () => {
		zoomImages = [].slice.call(document.querySelectorAll(".prodImage"));
		productImages = [].slice.call(document.querySelectorAll(".DetailsProdImg"));
		increment = 40;
		gestureBtns = document.querySelector(".gestures");
		mainSlider = document.getElementById("photoSwipeBox");
		zoomInBtn = document.getElementById("zoomInMain");
		zoomOutBtn = document.getElementById("zoomOutMain");
		a11yStatus = document.getElementById("zoomLiveRegion");
		moreGesturesArea = document.querySelector(".moreGestures");
		leftBtn = document.getElementById("leftArrowClick");
		rightBtn = document.getElementById("rightArrowClick");
	},
	a11yEvents = e => {
		if(e.target.id == "zoomOutMain"){
			zoomOut(e);
		}else if(e.target.id == "zoomInMain"){
			zoomIn(e);
		}else if(e.target.id == "panLeftMain"){
			e.preventDefault();
			moveZoom("left");
		}else if(e.target.id == "panRightMain"){
			e.preventDefault();
			moveZoom("right");
		}else if(e.target.id == "panUpMain"){
			e.preventDefault();
			moveZoom("up");
		}else if(e.target.id == "panDownMain"){
			e.preventDefault();
			moveZoom("down");
		}
	},
	setUpZoomDialog = () => {
		productImages.forEach((el) => {
			el.setAttribute("aria-expanded", "false");
			el.setAttribute("aria-describedby", "tapDescription");
		});
		initZoomDialog();
	},
	setUpWithoutDialog = () => {
		productImages.forEach((el) => {
			el.removeAttribute("aria-expanded");
			el.removeAttribute("aria-describedby");
		});
	},
	resetZoom = () => {
		if (mainSlider.querySelector(".tns-slide-active").hasAttribute("style")) 
			removeZoom();
		if (zoomInBtn.classList.contains("hide")) 
			closeZoom();
	},
	initZoomDialog = () => {
	     	const imageZoomDialog = new Dialog4i();
		const triggers = productImages;
		const dialogContent = '<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading zoom dialog, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
		
		imageZoomDialog.Init({
			triggerElements: triggers,
			dialogClass: "largeDialog", 
			title: "Loading View Larger Dialog", 
			content: dialogContent, 
			onOpen: openZoomDialog,
			onClose: closeZoomDialog
		});
	},
	zoomThis = e => {
		let el = mainSlider.querySelector(".tns-slide-active").querySelector(".DetailsProdImg"); // img parent div
		el.querySelector("img").style.opacity = "0";
		if (el.style.backgroundImage == "") el.style.backgroundImage = "url(" + el.getAttribute("href") + ")";
		let offsetX = e.offsetX,
		offsetY = e.offsetY,
		x = offsetX / el.offsetWidth * 100,
		y = offsetY / el.offsetHeight * 100;
		
		el.style.backgroundPositionX = x + '%';
		el.style.backgroundPositionY = y + '%';
		el.style.backgroundRepeat = "no-repeat";
	},
	removeZoom = e => {
		let el = mainSlider.querySelector(".tns-slide-active").querySelector(".DetailsProdImg"); // img parent div
		el.removeAttribute("style"); // remove background styles
		el.querySelector("img").removeAttribute("style"); // remove 0 opacity
	},
	zoomOut = e => {
		e.preventDefault();
		let zoomImg = mainSlider.querySelector(".tns-slide-active").querySelector(".DetailsProdImg");
		removeZoom();
		moreGesturesArea.classList.remove("show");
		moreGesturesArea.classList.add("hide");
		if (document.querySelector(".otherImagesJS")) { // if arrows are showing in the first place
			leftBtn.style.display = "block";
			rightBtn.style.display = "block";
		}
		zoomInBtn.classList.remove("hide");
		zoomInBtn.classList.add("show");
		zoomInBtn.setAttribute("aria-expanded", "false");
		a11yStatus.textContent = "";
		zoomInBtn.focus();
		zoomImg.removeEventListener("mouseleave", closeZoom);
	},
	zoomIn = e => {
		e.preventDefault();
		let zoomImg = mainSlider.querySelector(".tns-slide-active").querySelector(".DetailsProdImg");
		zoomImg.querySelector("img").style.opacity = 0;
		zoomImg.style.backgroundImage = "url(" + zoomImg.getAttribute("href") + ")";
		zoomImg.style.backgroundPositionY = "-200px";
		zoomImg.style.backgroundPositionX = "-200px";
		zoomImg.style.backgroundRepeat = "no-repeat";
		moreGesturesArea.classList.remove("hide");
		moreGesturesArea.classList.add("show");
		zoomInBtn.classList.remove("show");
		zoomInBtn.classList.add("hide");
		zoomInBtn.setAttribute("aria-expanded", "true");
		if (document.querySelector(".otherImagesJS")) { // if arrows are showing in the first place
			leftBtn.style.display = "none";
			rightBtn.style.display = "none";
		}
		zoomOutBtn.focus();
		zoomImg.addEventListener("mouseleave", closeZoom);
	},
	closeZoom = () => {
		zoomOutBtn.click();
	},
	moveZoom = moveDirection => {
		let zoomImg = mainSlider.querySelector(".tns-slide-active").querySelector(".DetailsProdImg"),
		position;
		if(moveDirection == "up" || moveDirection == "down") 
			position = zoomImg.style.backgroundPositionY;
		else if(moveDirection == "left" || moveDirection == "right")
			 position = zoomImg.style.backgroundPositionX;
		
		a11yStatus.textContent = "";
		
		if (position.length > 2) {
			let posNum = +position.substring(0, position.length - 2);
			if (moveDirection == "up" && posNum > -401) {
				posNum = posNum - increment;
				if (posNum < -401) posNum = -401;
			}else if(moveDirection == "down" && posNum < 0){
				posNum = posNum + increment;
				if (posNum > 0) posNum = 0;
			}else if(moveDirection == "left" && posNum < 0){
				posNum = posNum + increment;
				if (posNum > 0) posNum = 0;
			}else if(moveDirection == "right" && posNum > -321){
				posNum = posNum - increment;
				if (posNum < -321) posNum = -321;
			}
			
			if(moveDirection == "up" || moveDirection == "down"){
				zoomImg.style.backgroundPositionY = posNum + 'px';
			}else if(moveDirection == "left" || moveDirection == "right"){
			 	zoomImg.style.backgroundPositionX = posNum + 'px';
			}
			
			setTimeout(() => {
				a11yStatus.textContent = "Zoom image moved " + moveDirection;
			}, 300);
		}
	},
	setUpZoomDialogScroller = () => {
		let mainImgSrc;
		if(document.querySelector(".mainProdImg").getAttribute("data-src"))
			mainImgSrc = document.querySelector(".mainProdImg").getAttribute("data-src").replace("/300/", "/700/");
		else mainImgSrc = document.querySelector(".mainProdImg").getAttribute("src").replace("/300/", "/700/");
		
		zoomSliderHTML =
			'<div class="posRelative" aria-describedby="tapDescription">' +
				'<button class="zoomDialog-prev slider-prev"><svg class="textDarkestGray rotate270" height="20px" width="20px" focusable="false" role="img" aria-label="Previous Image"><title>Previous image</title> <use xlink:href="#svgIcon-arrowLine"></use> </svg></button>' +
				'<div class="Scroller zoomDialogSlider marginTop20 c-xs-quarter-2">' +
					'<div class="textCenter" data-zoom="' + mainImgSrc + '">' +
						'<img class="responsiveImg tns-lazy-img" data-src="' + mainImgSrc + '" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Large Product Photo" />' +
					'</div>';

		let extras = [].slice.call(document.querySelectorAll(".extraProdImg"));
		for (var i = 0; i < extras.length; i++) {
			let imageSrc = extras[i].querySelector("img").getAttribute("data-src").replace("/300/", "/700/");
			let imageAlt = extras[i].querySelector("img").getAttribute("alt");
			zoomSliderHTML += '<div class="textCenter" data-zoom="' + imageSrc + '"><img class="responsiveImg tns-lazy-img" data-src="' + imageSrc + '" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="' + imageAlt + '" /></div>';
		}

		zoomSliderHTML += '</div>' +
				'<button class="zoomDialog-next slider-next"><svg class="textDarkestGray rotate90" height="20px" width="20px" focusable="false" role="img" aria-label="Next Image"><title>Next image</title> <use xlink:href="#svgIcon-arrowLine"></use> </svg></button>' +
			'</div>' +
			'<p id="tapDescription" class="text16 textCenter marginTop20">Use fingers to zoom</p>';

		return zoomSliderHTML;
	},
	openZoomDialog = e => {
		document.getElementById("tapDescription").classList.add("hide");
		setUpZoomDialogScroller();
		document.getElementById("popUpDialog").querySelector(".dialogContent").innerHTML = zoomSliderHTML;
		document.getElementById("popUpDialog").querySelector("h2").textContent = "Viewing Larger " + document.querySelector("h1").textContent;

		zoomSlider = new tns({
			container: document.querySelector('.zoomDialogSlider'),
			rewind: true,
			lazyload: true,
			prevButton: document.querySelector('.zoomDialog-prev'),
			nextButton: document.querySelector('.zoomDialog-next'),
			speed: 500,
			nav: true,
			touch: false,
			navPosition: "bottom"
		});
		
		if(document.querySelector(".zoomDialogSlider").parentNode.parentNode.previousElementSibling.classList.contains("tns-liveregion")){
			document.querySelector(".zoomDialogSlider").parentNode.parentNode.previousElementSibling.setAttribute("role", "status");
		}
		if(imgSlider){
			let info = imgSlider.getInfo();
			let currentSlide = info.index;
			zoomSlider.goTo(currentSlide);
		}

		zoomSlider.events.on('transitionStart', () => {
			if (document.getElementById("popUpDialog").querySelector(".tns-slide-active").hasAttribute("style")) removeZoom();
		});
		
		disableSlideScroll(); //disabling scrolling on touch/drag so zooming with fingers on mobile does not trigger a slide change
	},
	closeZoomDialog = () => {
		document.getElementById("tapDescription").classList.remove("hide");
		let info = zoomSlider.getInfo();
		let currentSlide = info.index;
		if(imgSlider) imgSlider.goTo(currentSlide);
		setTimeout(() => {
			document.querySelector(".tns-nav-active").focus();
		}, 100);
	},
	disableSlideScroll = () => {
		let preventDrag = () => {
			zoomSlider.event.stopPropagation();
		};
		zoomSlider.events.off("dragStart", preventDrag);
		zoomSlider.events.on("dragStart", preventDrag);
	};
	return {
		Init: init
	};
};